<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.nomenclador')} / ${$t('menu.flights')} / ${$t('menu.conditionGenerals')}`">
        <ConditionsGeneralList />
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'

import ConditionsGeneralList from './tablas/ConditionsGeneralList.vue'

export default {
  components: {
    AppCardCode,
    ConditionsGeneralList,
  },
}
</script>
